import React from 'react';
import logo from './logo.svg';
import './App.css';
import SLogo from "./assets/slashLogo.png"

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <a href="https://www.goslash.com">
        <img width="100%" height="50%" src={SLogo} alt="logo" />
        </a>
      </header>
    </div>
  );
}

export default App;
